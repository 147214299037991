<script setup lang="ts">
import type { ClientApiError } from '@shopware-pwa/types';

const emits = defineEmits<{
  (e: 'success'): void;
  (e: 'close'): void;
}>();
const router = useRouter();
const { isLoggedIn, login } = useUser();
const { t } = useI18n();
const { refreshSessionContext } = useSessionContext();
const { mergeWishlistProducts } = useWishlist();
const { pushSuccess } = useNotifications();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const loginErrors = ref<string[]>([]);
const gtm = useGtm();

const formData = ref({
  username: '',
  password: '',
  remember: true,
});

const goToRegister = () => {
  emits('close');
  router.push(formatLink('/register'));
};

const invokeLogin = async (): Promise<void> => {
  loginErrors.value = [];
  try {
    // TODO: remove this line once the https://github.com/shopware/frontends/issues/112 issue is fixed
    await refreshSessionContext();
    await login(formData.value);
    emits('success');
    gtm?.trackEvent({ event: 'login', ecomm_pagetype: 'other' });
    pushSuccess(t('account.messages.loggedInSuccess'));
    emits('close');
    mergeWishlistProducts();
  } catch (error) {
    const e = error as ClientApiError;
    loginErrors.value = e.messages.map(({ detail }) => detail);
  }
};

const emailImputElement = ref();
useFocus(emailImputElement, { initialValue: true });
</script>
<template>
  <div
    id="modal-headline"
    role="form"
    title="Login form"
    aria-label="Login form"
    class="p-0"
  >
    <div v-if="!isLoggedIn" class="w-full space-y-8">
      <div>
        <h2 class="text-left text-base font-extrabold text-gray-900">
          {{ $t('account.signInLabel') }}
        </h2>
      </div>
      <form class="mt-8 space-y-6" @submit.prevent="invokeLogin">
        <input v-model="formData.remember" type="hidden" name="remember" />
        <div class="mb-10 grid grid-cols-12 gap-6">
          <div class="col-span-12 mb-4 md:col-span-6">
            <SharedSpecialInput
              id="email-address"
              ref="emailImputElement"
              v-model="formData.username"
              name="email"
              type="email"
              autocomplete="email"
              :label="$t('form.email')"
              required
            />
          </div>
          <div class="col-span-12 mb-2 md:col-span-6">
            <SharedSpecialInput
              id="password"
              ref="emailImputElement"
              v-model="formData.password"
              name="password"
              type="password"
              autocomplete="current-password"
              :label="$t('form.password')"
              required
            />
          </div>
        </div>

        <slot :data="formData" />

        <slot name="error">
          <div
            v-if="loginErrors.length"
            class="flex items-center justify-between"
          >
            <div class="flex items-center">
              <div
                class="login-errors rounded border-gray-300 text-red-600 focus:ring-indigo-500"
              >
                {{ loginErrors[0] }}
              </div>
            </div>
          </div>
        </slot>

        <div>
          <button class="btn btn-solid w-fit px-10" type="submit">
            {{ $t('account.signIn') }}
          </button>

          <!-- <div class="w-full mt-4" @click="$emit('close')">
            <button
              type="button"
              class="btn w-full"
              @click="goToRegister()"
            >
              {{ $t('account.signUp') }}
            </button>
          </div> -->
          <!-- <slot name="action"> </slot> -->
        </div>
      </form>
    </div>
    <div v-else>
      <h2>{{ $t('account.loggedInInfo') }}</h2>
      <button class="btn btn-solid" @click="$emit('close')">Okay</button>
    </div>
  </div>
</template>
